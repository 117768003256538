import React from "react"
import styled from "@emotion/styled"

export default function SlideButton({ Slide, Direction, theme = "dark"  }) {

  const Color = theme ==='dark' ? '#1e2550'  : '#c5c6ff'

  return (
    <Slider
      onClick={() => Slide()}
    >
      {Direction === "left" ?
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"
             stroke={Color}>
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="0.7" d="M15 19l-7-7 7-7" />
        </svg>

        : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"
               stroke={Color}>
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="0.7" d="M9 5l7 7-7 7" />
        </svg>}
    </Slider>
  )
}


const Slider = styled.div`
  border: none;
  background: transparent;
  font-weight: 100;
  cursor: pointer;
  align-self: center;  
  svg {
    height: 4em;
  }
  
  @media screen and (max-width:740px){
    svg {
      height: 2em;
    }
  }
`