import styled from "@emotion/styled"

export const Shadow = styled.div`
  transform: perspective(1500px) rotateX(-130deg) translateY(100%);
  background: ${props => props.theme === 'dark' ? 'linear-gradient(180deg, rgba(5,26,55,1) 0%, rgba(2,14,39,1) 100%)' : 'transparent'};
  width: 65%;
  height: 100%;
  position: absolute;
  top: 87%;
  left: 17%;
  box-shadow:0 -41px 43px 6px rgb(0 0 0 / 5%);
  border-radius: 15px ;
  z-index: 0;

  @media (max-width: 800px) {    
    width:71%;
    left: 14%;
  }

  @media (max-width: 700px) {
    width: 76%;
    left: 12%;
  }

`

