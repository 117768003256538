import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

export default function EbookCard({ id, theme = "dark" }) {
  const Color = theme === "dark" ? "#fff" : "#000"

  const data = useStaticQuery(graphql`
     { ebook:allMdx{
            edges {
            node {
            frontmatter {
                id
                slug
                ebook_image {
                  relativePath
                  childImageSharp {
                    fluid {
                      tracedSVG
                      srcWebp
                      srcSetWebp
                      originalImg
                      originalName
                    }
                  }
                }                
                title_1
                title_2
                accroche
              }
            }
          }
        }
      }    
  `)
  const ebookData = data.ebook.edges.find(
    edge => edge.node.frontmatter.id === id
  )
  return (
    <EbookContainer>
      <Link to={"/free-guides/" + ebookData.node.frontmatter.slug}
            style={{
              textDecoration: "none",
            }}
      >
        <Img
          placeholderStyle={{
            width: "fit-content",
            height: "130%",
            position: "unset",
          }}
          fluid={ebookData.node.frontmatter.ebook_image.childImageSharp.fluid}
          alt="ebook"
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke={Color}
               style={{ width: "7%", marginRight: "0.5em" }}
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
          </svg>
          <Text
            Color={Color}
          >
            Download
          </Text>
        </div>
      </Link>
    </EbookContainer>
  )
}

const EbookContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width :25%;
  margin : 1%;
  
  @media (max-width: 1020px) {
    max-width :35%;
  }
  
  @media (max-width: 800px) {
    max-width :42%;
  }

  @media (max-width: 600px) {
    max-width :62%;
  }

`

const Text = styled.p`
  color: ${props => props.Color};
  font-size: 15px
`