import React from "react"
import EbookCard from "../EbookCard/EbookCard"

export default function CarrousselContent({ state,theme }) {
  return (<div style={{
      display:"flex",
      zIndex :1,
      width:'70%',
      justifyContent:"center"
    }}>
      {state.map(mapped => {
        return <EbookCard id={mapped} theme={theme} />
      })}
    </div>
  )
}