import React, { useEffect, useState } from "react"
import { Shadow } from "./StyledCarroussel"
import SlideButton from "./Components/SlideButton/SlideButton"
import CarrousselContent from "./Components/CarrouselCtn/CarrouselCtn"
import styled from "@emotion/styled"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useScreenSize } from "../../Hook/useScreenSize"

export default function Carroussel({ theme = "dark", h2, h5, CTA }) {

  const ebooks = useStaticQuery(graphql`
     { ebook:allMdx{
            edges {
            node {
            frontmatter {
                id          
              }
            }
          }
        }
      }    
  `)

  const screenWidth = useScreenSize().width
  const [displayedCount, setDispayedCount] = useState(3)
  const [data, setData] = useState(ebooks.ebook.edges.map(mapped => mapped.node.frontmatter.id))

  useEffect(() => {
    if (screenWidth < 600) {
      setDispayedCount(1)
    } else if (screenWidth < 1020) {
      setDispayedCount(2)
    } else {
      setDispayedCount(3)
    }
  }, [screenWidth])


  const Increase = () => {
    const array = data.map((id) => {
        if (id < data.length) {
          return id + 1
        } else {
          return 1
        }
      }
    )
    setData(array)
  }

  const Decrease = () => {
    const array = data.map((id) => {
        if (id > 1) {
          return id - 1
        } else {
          return data.length
        }
      }
    )
    setData(array)
  }

  return (
    <>

      {(h2 !== undefined || h5 !== undefined) && (
        <Title>
          {h2 !== undefined &&
          <h2 dangerouslySetInnerHTML={{ __html: h2 }} />
          }
          {h5 !== undefined &&
          <h5 dangerouslySetInnerHTML={{ __html: h5 }} />
          }
        </Title>
      )}

      <div style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%"
      }}>
        <div style={{
          display: "flex",
          justifyContent: "center"
        }}>

          <SlideButton
            Slide={Decrease}
            Direction={"left"}
            theme={theme}
          />

          <CarrousselContent
            state={data.slice(0, displayedCount)}
            theme={theme}
            style={{
              width: "60%"
            }}
          />

          <SlideButton
            Slide={Increase}
            Direction={"right"}
            theme={theme}
          /></div>
        <Shadow
          theme={theme}
        />
      </div>

      {CTA !== undefined && <CTADiv>
        <Link className="cta-link" to={"/free-guides"}>
          {CTA}
        </Link>
        <hr />
      </CTADiv>}
    </>
  )
}


const Title = styled.div`
  color: white;
  text-align: center;
  margin-bottom: 5em;

  h2 {
    color: white;
  }

  h5 {
    color: white;
    font-size: 1.134133rem;
    font-weight: 400;
  }
`
const CTADiv = styled.div`
  margin: 6em auto 4em auto
`

